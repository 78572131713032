import { Button, Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import clsx from 'clsx';
import React, { FC, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import s from './RsvpForm.module.scss';

import { BigNumberField } from '#root/Components/BigNumberField';
import { Flex } from '#root/Components/Flex';
import { InfoBox } from '#root/Components/InfoBox';
import { Link } from '#root/Components/Link';
import { Loading } from '#root/Components/Loading';
import { usePageType } from '#root/hooks/use-page-type';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IParty, IPublicContact, IRsvpPublicFormData } from '#root/store/types';
import { Keys } from '#root/translations-keys';

export interface IProps {
  contact: IPublicContact | null;
}
export const RsvpForm: FC<IProps> = ({ contact }) => {
  const [, setSearchParams] = useSearchParams();
  const id = useStoreState(state => (state.party.party as IParty).id);
  const sid = useStoreState(state => (state.party.party as IParty).sid);
  const guestCountChoiceEnabled = useStoreState(
    state => (state.party.party as IParty).guestCountChoiceEnabled
  );
  const rsvpReplyEmailDisabled = useStoreState(
    state => (state.party.party as IParty).rsvpReplyEmailDisabled
  );
  const rsvpFields = useStoreState(state => (state.party.party as IParty).rsvpFields);

  const t = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const pageType = usePageType();
  const disabledFormAdmin = pageType === 'party' || pageType === 'new';
  const showRsvpLinkForAdmin = pageType === 'party';
  const {
    setError,
    control,
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IRsvpPublicFormData & { zip: string }>({
    defaultValues: {
      name: contact?.name ?? '',
      isComming: true,
      numberOfPeople: contact?.maxPeople ?? 1,
    },
    mode: 'onBlur',
  });

  const isComming = watch('isComming');

  const onSubmit: SubmitHandler<IRsvpPublicFormData & { zip: string }> = async data => {
    if (submitting || disabledFormAdmin) return;

    const { zip, ...rsvp } = data;
    if (zip.length) {
      return;
    }
    setSubmitting(true);
    getService('rsvp')
      .submit(id, { ...rsvp, contact: contact?.id })
      .then(responseRsvp => {
        if (!contact) {
          setSearchParams({ rid: responseRsvp.id });
        }
        getService('party').updateCurrentlyViewingRsvp(responseRsvp);
        // Trigger that user has submitted.
        setHasSubmitted(true);
      })
      .catch(async error => {
        if (error.response) {
          const body = await error.response.json();
          for (const [field, message] of Object.entries<string>(body.errors)) {
            setError(field as any, { type: 'custom', message });
          }
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className={s.wrapper}>
      {showRsvpLinkForAdmin ? (
        <div className={s.adminLink}>
          <Link to={`/${sid}/rsvps`} direct>
            {t(Keys.party.blocks.rsvp.admin_link)}
          </Link>
        </div>
      ) : null}
      {hasSubmitted ? (
        <InfoBox type="success">
          {contact
            ? t(Keys.party.blocks.rsvp.has_changed)
            : t(Keys.party.blocks.rsvp.has_submitted)}
        </InfoBox>
      ) : (
        <form
          className={clsx(s.form, disabledFormAdmin && s.disabled)}
          onSubmit={handleSubmit(onSubmit)}
        >
          <h2>{t(Keys.party.blocks.rsvp.title)}</h2>
          <Flex justify="space-between">
            <Controller
              control={control}
              name="isComming"
              render={({ field: { value, onChange } }) => (
                <RadioGroup
                  value={value ? '1' : '0'}
                  onChange={e => onChange(e.target.value === '1')}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label={
                      guestCountChoiceEnabled
                        ? t(Keys.party.blocks.rsvp.is_comming_amount.label)
                        : t(Keys.party.blocks.rsvp.is_comming.label)
                    }
                  />
                  <FormControlLabel
                    value="0"
                    control={<Radio />}
                    label={t(Keys.party.blocks.rsvp.not_comming.label)}
                  />
                </RadioGroup>
              )}
            />
            {isComming && guestCountChoiceEnabled ? (
              <Controller
                control={control}
                name="numberOfPeople"
                shouldUnregister
                render={({ field: { onChange, value } }) => (
                  <BigNumberField
                    maxValue={contact?.maxPeople}
                    value={value}
                    onChange={val => onChange(val ?? 1)}
                  />
                )}
              />
            ) : null}
          </Flex>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t(Keys.generic.name)}
            disabled={!!contact}
            inputProps={register('name', { required: !contact })}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={t(Keys.generic.comment)}
            multiline
            rows={3}
            inputProps={register('comment')}
          />
          {!contact?.hasEmail && !disabledFormAdmin && !rsvpReplyEmailDisabled ? (
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              helperText={t(Keys.party.blocks.rsvp.email_helper_text)}
              error={!!errors.email}
              label={errors.email?.message ?? t(Keys.generic.email)}
              inputProps={register('email', {
                pattern: { value: /^\S+@\S+\.\S+$/, message: t(Keys.generic.email_error) },
              })}
            />
          ) : null}

          {isComming &&
            rsvpFields
              .filter(field => !field.hidden) // Even though this gets filtered by API, it does not for the owner.
              .map(rsvpField =>
                rsvpField.type === 'text' ? (
                  <TextField
                    key={rsvpField.id}
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label={rsvpField.name}
                    required={rsvpField.required}
                    inputProps={register(`fields.${rsvpField.id}`, {
                      required: rsvpField.required,
                      shouldUnregister: true,
                    })}
                  />
                ) : (
                  <FormControlLabel
                    key={rsvpField.id}
                    control={
                      <Controller
                        control={control}
                        name={`fields.${rsvpField.id}`}
                        shouldUnregister
                        rules={{ required: rsvpField.required }}
                        render={({ field: { value, ...rest } }) => (
                          <Checkbox checked={!!value} required={rsvpField.required} {...rest} />
                        )}
                      />
                    }
                    required={rsvpField.required}
                    label={rsvpField.name}
                  />
                )
              )}

          <TextField
            inputProps={register('zip')}
            style={{ visibility: 'hidden', height: 0 }}
            fullWidth={true}
            autoComplete="off"
            tabIndex={-1}
          />
          <div className={s.gdpr}>{t(Keys.party.blocks.rsvp.gdpr)}</div>
          <Button
            disableElevation
            type="submit"
            disabled={submitting}
            color="primary"
            variant="contained"
          >
            {submitting ? <Loading /> : t(Keys.generic.submit)}
          </Button>
        </form>
      )}
    </div>
  );
};
