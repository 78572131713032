import { Block, DoneAll, MarkEmailRead } from '@mui/icons-material';
import { Button, Checkbox, IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import clsx from 'clsx';
import React, { FC, useCallback, useRef, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useBeforeUnload } from 'react-use';
import isMobilePhone from 'validator/es/lib/isMobilePhone';

import s from './Contact.module.scss';

import { BigNumberField } from '#root/Components/BigNumberField';
import { CopyButton } from '#root/Components/CopyButton';
import { DropdownMenu } from '#root/Components/DropdownMenu';
import { Flex, Item } from '#root/Components/Flex';
import { Loading } from '#root/Components/Loading';
import { useAutoSubmit } from '#root/hooks/use-auto-submit';
import { useIsBreakpoints } from '#root/hooks/use-breakpoint';
import { useConfirm } from '#root/hooks/use-confirm';
import { useTrackInputFocus } from '#root/hooks/use-track-input-focus';
import { useTranslation } from '#root/hooks/use-translation';
import { getService, useStoreState } from '#root/store';
import { IContact } from '#root/store/types';
import { Keys } from '#root/translations-keys';
import { css } from '#root/utils/css';
import { CloseIcon, EyeIcon, SaveIcon, ThreeDotsIcon } from '#root/utils/icons';

export interface IProps {
  item?: IContact;
  className?: string;
  autoSubmit?: boolean;
  showLabels?: boolean;
  onChange: (contact: IContact) => void;
  onDelete: (contact: IContact) => void;
}

const defaultValues = {
  name: '',
  email: '',
  phone: '',
  receiveEmail: false,
  receiveSms: false,
  maxPeople: Infinity,
} satisfies Partial<IContact>;

export const EditContact: FC<IProps> = ({
  item,
  className,
  autoSubmit,
  showLabels,
  onChange,
  onDelete,
}) => {
  const t = useTranslation();
  const isMobile = useIsBreakpoints(['small', 'medium']);
  // Showing labels when on mobile as they are stacked. Also true when used in edit dialog.
  const doShowLabels = showLabels || isMobile;
  const party = useStoreState(state => state.party.party);
  if (!party) return null;
  const formRef = useRef<HTMLFormElement>(null);
  const [submitting, setSubmitting] = useState(false);
  const { isConfirmed } = useConfirm();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty, isValid },
    setValue,
    getValues,
    setFocus,
    clearErrors,
    setError,
    reset,
  } = useForm<IContact>({
    defaultValues: item ?? defaultValues,
    mode: 'onBlur',
  });

  useBeforeUnload(isDirty, t(Keys.generic.unsaved_changes_loss));

  const { hasFocus: nameHasFocus, field: nameHasFocusField } = useTrackInputFocus();
  const { hasFocus: emailHasFocus, field: emailHasFocusField } = useTrackInputFocus();
  const { hasFocus: phoneHasFocus, field: phoneHasFocusField } = useTrackInputFocus();

  const handleOnDelete = async () => {
    if (
      item &&
      (await isConfirmed(Keys.admin.contacts.delete.confirm, Keys.generic.delete, 'error'))
    ) {
      onDelete(item);
      getService('contact').delete(party.id, item.id);
    }
  };

  const shouldSubmit = useCallback(() => {
    return (
      !!autoSubmit && !nameHasFocus.current && !emailHasFocus.current && !phoneHasFocus.current
    );
  }, [autoSubmit]);

  const onSubmit: SubmitHandler<IContact> = useCallback(
    async data => {
      if (submitting || !isDirty || !isValid) return;

      setSubmitting(true);

      const emailOrPhoneHasChanged = data.email !== item?.email || data.phone !== item?.phone;
      if (
        item?.hasReceivedInvite &&
        emailOrPhoneHasChanged &&
        (await isConfirmed(
          Keys.admin.contacts.change.already_received_invite,
          Keys.generic.save
        )) === false
      ) {
        setSubmitting(false);
        reset();
        return;
      }
      getService('contact')
        .save(party.id, data)
        .then(async contact => {
          onChange(contact);
          if (item) {
            // In case we are editing an existing.
            reset(contact);
          } else {
            reset();
          }
        })
        .catch(async error => {
          if (error.response) {
            const body = await error.response.json();
            for (const [field, message] of Object.entries<string>(body.errors)) {
              if (field === 'root') {
                alert(message);
              }
              setError(field as any, { type: 'custom', message });
            }
          }
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [submitting, party, isDirty, isValid, onChange]
  );

  const doTriggerSubmit = useAutoSubmit(watch, handleSubmit, onSubmit, shouldSubmit);

  return (
    <>
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className={clsx(className, s.form)}>
        <div className={s.label}>
          <TextField
            variant="outlined"
            fullWidth
            label={
              errors.name?.message ??
              (doShowLabels ? t(keys => keys.admin.contacts.labels.names) : undefined)
            }
            InputLabelProps={{
              shrink: true,
            }}
            placeholder={t(keys => keys.admin.contacts.labels.names)}
            {...nameHasFocusField}
            inputProps={register('name', {
              onBlur: doTriggerSubmit,
              required: true,
              minLength: { value: 1, message: t(keys => keys.generic.required) },
            })}
          />
        </div>
        <div className={s.label}>
          <TextField
            type="email"
            variant="outlined"
            fullWidth
            placeholder="email@email.dk"
            error={!!errors.email}
            label={
              errors.email?.message ??
              (doShowLabels ? t(keys => keys.admin.contacts.labels.email) : undefined)
            }
            {...emailHasFocusField}
            inputProps={register('email', {
              onBlur: doTriggerSubmit,
              onChange: e =>
                e.target.value.length > 0
                  ? setValue('receiveEmail', true)
                  : setValue('receiveEmail', false),
              required: {
                value: getValues('receiveEmail'),
                message: t(keys => keys.generic.required),
              },
              pattern: { value: /^\S+@\S+\.\S+$/, message: t(keys => keys.generic.email_error) },
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Controller
                    name="receiveEmail"
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        size="small"
                        checked={props.value}
                        tabIndex={-1}
                        onChange={e => {
                          if (!e.target.checked) {
                            clearErrors('email');
                          } else {
                            setFocus('email');
                          }
                          props.onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={s.label}>
          <TextField
            type="tel"
            variant="outlined"
            fullWidth
            placeholder="+45"
            error={!!errors.phone}
            label={
              errors.phone?.message ??
              (doShowLabels ? t(keys => keys.admin.contacts.labels.phone) : undefined)
            }
            {...phoneHasFocusField}
            inputProps={register('phone', {
              onBlur: doTriggerSubmit,
              onChange: e =>
                e.target.value.length > 0
                  ? setValue('receiveSms', true)
                  : setValue('receiveSms', false),
              validate: value => {
                if (getValues('receiveSms') && !value) {
                  return t(keys => keys.generic.required);
                }
                return !value || isMobilePhone(value, 'any');
              },
            })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Controller
                    name="receiveSms"
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        size="small"
                        checked={props.value}
                        tabIndex={-1}
                        onChange={e => {
                          if (!e.target.checked) {
                            clearErrors('phone');
                          } else {
                            setFocus('phone');
                          }
                          props.onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Flex align="center">
          <div className={clsx(s.label, s.guestCount)}>
            {party.guestCountChoiceEnabled ? (
              <Controller
                control={control}
                name="maxPeople"
                shouldUnregister
                render={({ field: { onChange, value } }) => (
                  <BigNumberField
                    infinit
                    label={doShowLabels ? t(keys => keys.admin.contacts.labels.people) : undefined}
                    compact
                    value={value}
                    onChange={val => onChange(val ?? undefined)}
                  />
                )}
              />
            ) : null}
          </div>
          <Item pushRight>
            <div className={s.icons}>
              {item ? (
                <>
                  <DropdownMenu
                    icon={<ThreeDotsIcon />}
                    IconButtonProps={{ size: 'small' }}
                    className={s.dropdownMenu}
                  >
                    {({ closeDropdown }) => (
                      <Flex gap className={css.spaceS}>
                        <Tooltip
                          title={t(
                            item.hasReceivedInvite
                              ? Keys.admin.contacts.delete.already_received_invite
                              : Keys.admin.contacts.delete.confirm
                          )}
                        >
                          <span>
                            <IconButton
                              color="error"
                              disabled={item.hasReceivedInvite}
                              onClick={() => closeDropdown(handleOnDelete())}
                            >
                              <CloseIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title={t(keys => keys.admin.contacts.copy_url)}>
                          <span>
                            <CopyButton
                              onClick={closeDropdown}
                              value={`https://invii.me/${party.slug ?? party.sid}?cid=${
                                item.sid || item.id
                              }`}
                            />
                          </span>
                        </Tooltip>
                      </Flex>
                    )}
                  </DropdownMenu>
                  <div className={clsx(s.saveButton, !isDirty && s.saveButtonHidden)}>
                    <IconButton
                      disabled={!isValid}
                      type="submit"
                      color="primary"
                      title={t(keys => keys.generic.save)}
                    >
                      {submitting ? <Loading /> : <SaveIcon />}
                    </IconButton>
                  </div>
                </>
              ) : null}
              {item?.hasReceivedInvite ? (
                <div className={clsx(s.firstVisit, css.showOnDesktop)}>
                  <Tooltip title={t(keys => keys.admin.contacts.has_received_invite)}>
                    <MarkEmailRead fontSize="small" color="primary" />
                  </Tooltip>
                </div>
              ) : null}
              {item?.firstVisit ? (
                <div className={clsx(s.firstVisit, css.showOnDesktop)}>
                  <Tooltip title={t(keys => keys.admin.contacts.first_visit)}>
                    <EyeIcon fontSize="small" color="primary" />
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </Item>
        </Flex>
        {!item ? (
          <Button
            disabled={!isValid || submitting}
            type="submit"
            disableElevation
            variant="contained"
            className={s.createButton}
          >
            {submitting ? <Loading /> : t(keys => keys.admin.contacts.create_button)}
          </Button>
        ) : null}
      </form>
      {item?.rsvp ? (
        <div
          className={clsx(
            s.rsvp,
            css.fontSmall,
            css.fontSans,
            css.fontColorGrey2,
            css.showOnDesktop
          )}
        >
          <Tooltip
            title={t(
              item.rsvp.isComming
                ? Keys.admin.contacts.is_comming
                : Keys.admin.contacts.is_not_comming
            )}
          >
            <Flex align="center" gap>
              {item.rsvp.isComming ? (
                <DoneAll fontSize="small" color="primary" />
              ) : (
                <Block fontSize="small" color="error" />
              )}
              {t(
                item.rsvp.isComming
                  ? Keys.admin.contacts.is_comming
                  : Keys.admin.contacts.is_not_comming
              )}
            </Flex>
          </Tooltip>
        </div>
      ) : null}
    </>
  );
};
